import React from 'react';
import aspNetImage from '../../assets/images/technology/Asp.Net.png';
import sqlServerImage from '../../assets/images/technology/SQLServer.png';
import devexpressImage from '../../assets/images/technology/Devexpress.png';
import reactImage from '../../assets/images/technology/React.png';
import nodejsImage from '../../assets/images/technology/Nodejs.png';
import mongoDbImage from '../../assets/images/technology/mongo db.png';
import postgresqlImage from '../../assets/images/technology/postgresql.png';
import flutterImage from '../../assets/images/technology/flutter.png';
import pythonImage from '../../assets/images/technology/python.png';
import angularImage from '../../assets/images/technology/angular.png';
import visualStudioImage from '../../assets/images/technology/VisualStudio.png';
import visualStudioCodeImage from '../../assets/images/technology/VisualStudiocode.png';

const TechnologySection = () => {
  return (
    <section className="section technology bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span className="h6 text-color">Our Technology</span>
              <h2 className="mt-3 content-title">Technologies and Tools We Use</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="technology-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={aspNetImage} alt="ASP.Net" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={sqlServerImage} alt="SQL Server" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={devexpressImage} alt="Devexpress" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={reactImage} alt="React" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={nodejsImage} alt="Node.js" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={mongoDbImage} alt="MongoDB" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={postgresqlImage} alt="PostgreSQL" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={flutterImage} alt="Flutter" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={pythonImage} alt="Python" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={angularImage} alt="Angular" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={visualStudioImage} alt="Visual Studio" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
              <div className="technology-item">
                <img src={visualStudioCodeImage} alt="Visual Studio Code" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologySection;
