import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/style.css'; // Main stylesheet
import './plugins/bootstrap/css/bootstrap.min.css'; // Bootstrap CSS
import './plugins/themify/css/themify-icons.css'; // Themify Icons
import './plugins/fontawesome/css/all.css'; // FontAwesome
import './plugins/magnific-popup/dist/magnific-popup.css'; // Magnific Popup
import './plugins/slick-carousel/slick/slick.css'; // Slick Carousel
 import './plugins/slick-carousel/slick/slick-theme.css'; // Slick Theme




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
