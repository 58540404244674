import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="section about position-relative">
      <div className="bg-about"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-6 offset-md-0">
            <div className="about-item">
              <span className="h6 text-color">What we are</span>
              <h2 className="mt-3 mb-4 position-relative content-title">
                We are dynamic team of creative people
              </h2>
              <div className="about-content">
                <h4 className="mb-3 position-relative">
                  We are Perfect Solution
                </h4>
                <p className="mb-5">
                  <b>
                    Prime Soft <span style={{ color: "red" }}>Solutions</span>
                  </b>{" "}
                  is a provider of IT consulting and software development
                  services. We offer Product Development, Cloud, Quality
                  Assurance and Consulting services to clients from
                  venture-funded startups to publicly traded companies in
                  E-Commerce, Healthcare, Networking, Telecom, and Banking.
                </p>
                <Link
                  to="#" // Replace with your actual route
                  className="btn btn-main btn-round-full"
                  style={{
                    border: "2px solid #f74234", // Add border with the desired color
                    borderRadius: "8px", // Add border radius for rounded corners
                    // color: 'white', // Set text color to white
                  }}
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
