import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Home'
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Services from './pages/Services';
import ScrollToTop from './components/ScrollToTop';
import Portfolio from './pages/Portfolio';
import ContactUs from './pages/ContactUs';

function App() {
  return (
    <Router basename="/" >
        <ScrollToTop />
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/contact" element={<ContactUs/>} />
          </Routes>
          <Footer/>
    </Router>
  );
}

export default App;
