import React from 'react';
import { Link } from 'react-router-dom';
import aboutImage from '../../assets/images/about/home-7.jpg'; // Update the path based on your project structure
const AboutSection = () => {
  return (
    <section className="section about-2 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="about-item pr-3 mb-5 mb-lg-0">
              <span className="h6 text-color">What we are</span>
              <h2 className="mt-3 mb-4 position-relative content-title">
                We are a dynamic team of creative people
              </h2>
              <p className="mb-5">
                We provide innovative software solutions across various sectors, including the medical and financial fields. Our expertise also extends to developing custom software for surveys and more. We help businesses achieve their goals by optimizing processes and enhancing operational efficiency.
              </p>
              <Link to="/get-started" className="btn btn-main btn-round-full">
                Get started
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="about-item-img">
              <img src={aboutImage} alt="About Us" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
