import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <section className="mt-70 position-relative">
      <div className="container">
        <div className="cta-block-2 bg-gray p-5 rounded border-1">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7">
              <span className="text-color">For Every type business</span>
              <h2 className="mt-2 mb-4 mb-lg-0">
                Entrust Your Project to Our Best Team of Professionals
              </h2>
            </div>
            <div className="col-lg-4">
  <Link 
    to="/contact" // Use the appropriate route for your React app
    className="btn btn-main btn-round-full float-lg-right"
    style={{
      textDecoration: 'none', // Remove underline
      color: 'inherit', // Inherit color from parent, or specify as needed
      border: "2px solid #f74234", // Add border with the desired color
      borderRadius: "8px",
    }}
  >
    Contact Us
  </Link>
</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
