import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <section className="section cta">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <div className="cta-item bg-white p-5 rounded">
              <span className="h6 text-color">We create for you</span>
              <h2 className="mt-2 mb-4">
                Entrust Your Project to Our Best Team of Professionals
              </h2>
              <p className="lead mb-4">
                Have any project on mind? For immediate support:
              </p>
              <h4>
                <Link
                  to="mailto:info@eprimesoftsolutions.com" // Use Link to for internal navigation, keep mailto for email
                  style={{
                    textDecoration: "none", // Remove underline
                    color: "#222328", // Initial text color
                    transition: "color 0.3s ease", // Smooth transition for color change
                    display: "flex", // Flexbox for alignment
                    alignItems: "center", // Center items vertically
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#f77988")
                  } // Change color on hover
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#222328")
                  } // Reset color when not hovering
                >
                  <i className="fa fa-envelope mr-2"></i>
                  <span>info@eprimesoftsolutions.com</span>
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
