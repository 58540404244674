import React from 'react';

const servicesData = [
  {
    icon: 'ti-desktop',
    title: 'Web Development',
    description: 'We offer web development services using ASP.NET or MERN stack, tailored to meet your specific business needs.',
  },
  {
    icon: 'ti-mobile',
    title: 'Mobile App Development',
    description: 'Our expertise includes developing mobile apps using Flutter, ensuring seamless performance and user experience.',
  },
  {
    icon: 'ti-desktop',
    title: 'Windows App Development',
    description: 'We specialize in creating robust Windows applications with DevExpress, delivering powerful and user-friendly solutions.',
  },
  {
    icon: 'ti-palette',
    title: 'Interface Design',
    description: 'Our team excels in crafting intuitive and engaging interface designs that enhance user interaction and satisfaction.',
  },
  {
    icon: 'ti-pie-chart',
    title: 'Data Engineering',
    description: 'We provide data engineering services using Python, enabling efficient data management and processing.',
  },
  {
    icon: 'ti-bar-chart',
    title: 'Data Analysis',
    description: 'Our data analysis services leverage various tools to extract actionable insights, helping you make informed business decisions.',
  },
  {
    icon: 'ti-exchange-vertical',
    title: 'Data Scraping',
    description: 'We offer advanced data scraping services to extract valuable information from various sources, helping you make data-driven decisions.',
  },
  {
    icon: 'ti-pencil-alt',
    title: 'Content Creation',
    description: 'Our content creation services produce compelling and relevant content that resonates with your audience and drives engagement.',
  },
  {
    icon: 'ti-flag',
    title: 'Branding',
    description: 'We help build strong and memorable brands, ensuring your business stands out in the competitive market.',
  },
];

const ServicesData = () => {
  return (
    <section className="section service border-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span className="h6 text-color">Our Services</span>
              <h2 className="mt-3 content-title">We provide a wide range of creative services</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {servicesData.map((service, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
              <div className="service-item mb-5">
                <i className={service.icon}></i>
                <h4 className="mb-3">{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesData;
