import React from 'react'
import TitleServices from '../components/services/TitleServices'
import ServicesData from '../components/services/ServicesData'
import ServicesCallToAction from '../components/services/ServicesCallToAction'

const Services = () => {
  return (
    <div>
      <div className="main-wrapper">
      <TitleServices/>
      <ServicesData/>
      <ServicesCallToAction/>
      </div>
      </div>
  )
}

export default Services