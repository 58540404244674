import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "#222328", // Initial text color
    transition: "color 0.3s ease", // Smooth transition for color change
  };

  const linkHoverStyle = {
    color: "#f77988", // Hover text color
  };

  return (
    <div className="main-wrapper">
      <footer className="footer section">
        <div className="container">
          <div className="row">
            {/* Company Links */}
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="widget">
                <h4
                  className="text-capitalize mb-4"
                  style={{ color: "#222328" }}
                >
                  Company
                </h4>
                <ul className="list-unstyled footer-menu lh-35">
                  {[
                    "Terms & Conditions",
                    "Privacy Policy",
                    "Support",
                    "FAQ",
                  ].map((item, index) => (
                    <li key={index}>
                      <Link
                        to={`/${item.replace(/ & /, "").toLowerCase()}`}
                        style={linkStyle}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.color = linkHoverStyle.color)
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.color = linkStyle.color)
                        }
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Quick Links */}
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="widget">
                <h4
                  className="text-capitalize mb-4"
                  style={{ color: "#222328" }}
                >
                  Quick Links
                </h4>
                <ul className="list-unstyled footer-menu lh-35">
                  {["About", "Services", "Team", "Contact"].map(
                    (item, index) => (
                      <li key={index}>
                        <Link
                          to={`/${item.toLowerCase()}`}
                          style={linkStyle}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.color = linkHoverStyle.color)
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = linkStyle.color)
                          }
                        >
                          {item}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            {/* Contact Info */}
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="widget">
                <div className="logo mb-4">
                  <h3>
                    PrimeSoft<span>Solutions.</span>
                  </h3>
                </div>
                <h6 style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                  <Link
                    to="tel:+92-3044870692"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    +92-3044870692
                  </Link>
                </h6>
                <Link
                  to="mailto:info@eprimesoftsolutions.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <span className="text-color h4">
                    info@eprimesoftsolutions.com
                  </span>
                </Link>
              </div>
            </div>
          </div>

          {/* Footer Bottom Section */}
          <div className="footer-btm pt-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="copyright">
                  &copy; Copyright Reserved to{" "}
                  <span className="text-color">PrimeSoftSolutions</span>
                </div>
              </div>
              <div className="col-lg-6 text-left text-lg-right">
                <ul className="list-inline footer-socials">
                  {["Facebook", "Twitter", "Linkedin"].map(
                    (platform, index) => (
                      <li className="list-inline-item" key={index}>
                        <Link
                          to={
                            platform === "Linkedin"
                              ? "https://www.linkedin.com/company/primesoftsolutions/?viewAsMember=true"
                              : "#"
                          }
                          style={linkStyle}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.color = linkHoverStyle.color)
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = linkStyle.color)
                          }
                          target={platform === "Linkedin" ? "_blank" : ""}
                        >
                          <i
                            className={`ti-${platform.toLowerCase()} mr-2`}
                          ></i>
                          {platform}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
