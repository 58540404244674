import React from "react";
import { Link } from "react-router-dom";
const Slider = () => {
  return (
    <div className="main-wrapper">
      {/* Slider Start */}
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-10">
              <div className="block">
                <span className="d-block mb-3 text-white text-capitalize">
                  Prepare for new future
                </span>
                <h1 className="animated fadeInUp mb-5">
                  Our work is <br />
                  presentation of our <br />
                  capabilities.
                </h1>
                <Link
                  to="#" // Replace with your actual route
                  className="btn btn-main animated fadeInUp btn-round-full"
                  style={{
                    border: "2px solid #f74234", // Add border with the desired color
                    borderRadius: "8px", // Add border radius for rounded corners
                    color: "white",
                  }}
                >
                  Get started<i className="btn-icon fa fa-angle-right ml-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Slider End */}
    </div>
  );
};

export default Slider;
