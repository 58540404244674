import React from "react";

const Intro = () => {
  return (
    <section className="section intro">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="section-title">
              <span className="h6 text-color">
                We are creative & expert people
              </span>
              <h2 className="mt-3 content-title">
                We work with business & provide solution to client with their
                business problem
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="intro-item mb-5 mb-lg-0">
              <i className="ti-desktop color-one"></i>
              <h4 className="mt-4 mb-3">Software Development</h4>
              <p>
                We Focus on Quality. We work together with our Clients to build
                a Compelling Product that provides lasting excitement and value
                to our customers.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="intro-item mb-5 mb-lg-0">
              <i className="ti-android color-one"></i>
              <h4 className="mt-4 mb-3">Mobile Apps Development</h4>
              <p>
                We are the best mobile apps development company, offering
                awesome multiplatform mobile application development services
                with excellent performance and best user experience.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="intro-item">
              <i className="ti-layers-alt color-one"></i>
              <h4 className="mt-4 mb-3">Web Development</h4>
              <p>
                Responsive & Reliable. We Plan, Design, & Develop Websites from
                start to finish, ensuring a high ROI for clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
