import React, { useState } from 'react';
import styles from './ProjectDisplay.module.css'; 
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'; // Add FaTimes for the close icon
import thumb1 from '../../assets/images/portfolio/Portfolio1/FastFoodFrontPage.jpeg';
import thumb2 from '../../assets/images/portfolio/Portfolio2/HopitalFrontPage.png';
import img1_1 from '../../assets/images/portfolio/Portfolio1/Port1_1.jpeg';
import img1_2 from '../../assets/images/portfolio/Portfolio1/Port1_2.png';
import img2_1 from '../../assets/images/portfolio/Portfolio2/Port2_1.png';
import img2_2 from '../../assets/images/portfolio/Portfolio2/Port2_2.png';
import thumb3 from '../../assets/images/portfolio/Portfolio3/HomePage.jpeg';
import img3_1 from '../../assets/images/portfolio/Portfolio3/HomePage.jpeg';
import img3_2 from '../../assets/images/portfolio/Portfolio3/HomePage.jpeg';
// import img3_3 from '../../assets/images/portfolio/Portfolio3/Port3_3.png';


const projects = [
    {
        id: 1,
        title: 'Project California',
        thumbnail: thumb1,
        images: [img1_1, img1_2],
        descriptions: [
          `
            <p>Chicken Huus is an innovative e-commerce platform designed to revolutionize the fast food ordering experience. 
            Our project focuses on providing customers with a highly customizable and user-friendly online shopping experience for all their fast food cravings. 
            With just a few clicks, customers can order their favorite fast food items and personalize them according to their preferences, ensuring a unique and satisfying meal every time.</p>
            
            <h3>Technologies Used:</h3>
            <p>C#, MVC .Net, SQL Server, Bootstrap</p>
          `,
          `
            <p>Chicken Huus is an innovative e-commerce platform designed to revolutionize the fast food ordering experience. 
            Our project focuses on providing customers with a highly customizable and user-friendly online shopping experience for all their fast food cravings. 
            With just a few clicks, customers can order their favorite fast food items and personalize them according to their preferences, ensuring a unique and satisfying meal every time.</p>
            
            <h3>Technologies Used:</h3>
            <p>C#, MVC .Net, SQL Server, Bootstrap</p>
          `
        ],
    },
    
  {
    id: 2,
    title: 'Project Florida',
    thumbnail: thumb2,
    images: [thumb2, img2_2],
    descriptions: [
      `
        <p>It is a comprehensive web-based patient management system designed to streamline and enhance the administrative and clinical processes within healthcare facilities. 
        Our project aims to improve efficiency, accuracy, and patient care by providing a robust platform that integrates various essential modules. 
        With MediCare Plus, healthcare providers can easily manage patient information, billing, discharge processes, doctor shares, and account management, all within a single, user-friendly interface.</p>
        
        <h3>Key Features:</h3>
        <ul>
          <li>Patient Registration</li>
          <li>Patient Billing</li>
          <li>Patient Discharge</li>
          <li>Doctor Share</li>
          <li>Account Management</li>
        </ul>

        <h3>Technologies Used:</h3>
        <p>C#, MVC .Net, SQL Server, Bootstrap</p>
      `,
      `
        <p>It is a comprehensive web-based patient management system designed to streamline and enhance the administrative and clinical processes within healthcare facilities. 
        Our project aims to improve efficiency, accuracy, and patient care by providing a robust platform that integrates various essential modules. 
        With MediCare Plus, healthcare providers can easily manage patient information, billing, discharge processes, doctor shares, and account management, all within a single, user-friendly interface.</p>
        
        <h3>Key Features:</h3>
        <ul>
          <li>Patient Registration</li>
          <li>Patient Billing</li>
          <li>Patient Discharge</li>
          <li>Doctor Share</li>
          <li>Account Management</li>
        </ul>

        <h3>Technologies Used:</h3>
        <p>C#, MVC .Net, SQL Server, Bootstrap</p>
      `
    ],
},

  {
    id: 3,
    title: 'Project New York',
    thumbnail: thumb3,
    images: [img3_1, img3_2],
    descriptions: [
      `
        <p>We have developed a comprehensive web application that caters to the needs of pet owners, doctors, and admins. 
        <b>PetMediConnect</b> brings together various functionalities to ensure the best care for pets and a seamless experience for users.</p>
        
        <h3>Key Features:</h3>
        <ul>
          <li><strong>Online Pet Checkups & Appointments:</strong> Users can easily book appointments with vets, including online consultations via Zoom.</li>
          <li><strong>Pet Store:</strong> A wide range of products like pet food, accessories, and medicines, all available online with easy payments through Stripe.</li>
          <li><strong>Community Forum:</strong> A platform where users can ask questions, share experiences, and connect with other pet owners.</li>
          <li><strong>Chatbot Integration:</strong> For quick answers to general queries about the app and its features.</li>
          <li><strong>Email Service:</strong> Automated emails for appointment confirmations and updates.</li>
          <li><strong>Twilio Message Service:</strong> SMS notifications for appointment reminders and confirmations.</li>
          <li><strong>Zoom API Integration:</strong> Facilitating online consultations directly through the app.</li>
          <li><strong>Stripe for Payment:</strong> Secure and seamless payment processing for consultations and store purchases.</li>
        </ul>
  
        <h3>Roles:</h3>
        <ul>
          <li><strong>Admin:</strong> Manages doctors' registrations, appointments, store products, and payment approvals.</li>
          <li><strong>Doctor:</strong> Manages their profile, appointments, and approves patient consultations.</li>
          <li><strong>User/Patient:</strong> Registers, books appointments, makes payments, purchases products, and engages in the community forum.</li>
        </ul>
  
        <h3>Technologies Used:</h3>
        <p>MERN (MongoDB, Express, React, Node.js)</p>
      `
    ]
  }
];

const ProjectDisplay = () => {
  const [currentProject, setCurrentProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openPopup = (project) => {
    setCurrentProject(project);
    setCurrentImageIndex(0);
  };

  const closePopup = () => {
    setCurrentProject(null);
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % currentProject.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + currentProject.images.length) % currentProject.images.length);
  };

  return (
    <div className={styles.projectDisplay}>
      <div className={styles.projectsGrid}>
        {projects.map((project) => (
          <div key={project.id} className={styles.projectCard} onClick={() => openPopup(project)}>
            <img
              src={project.thumbnail}
              alt={`Thumbnail of ${project.title}`}
              className={styles.projectThumbnail}
            />
            <h3>{project.title}</h3>
            <p>{project.intro}</p> {/* This should display the intro text correctly */}
          </div>
        ))}
      </div>

      {currentProject && (
        <div className={styles.popupOverlay} onClick={closePopup}>
          <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
            {/* Close Icon */}
            <FaTimes className={styles.popupCloseIcon} onClick={closePopup} />
            
            {/* Popup Header */}
            <h2 className={styles.popupTitle}>{currentProject.title}</h2>

            {/* Project Image */}
            <div className={styles.popupImageContainer}>
              <img
                src={currentProject.images[currentImageIndex]} 
                alt={`Project ${currentProject.title} Image ${currentImageIndex + 1}`} 
                className={styles.popupImage}
              />
            </div>

            {/* Navigation Icons */}
            <div className={styles.popupNavigation}>
              <FaChevronLeft onClick={prevImage} className={styles.navIcon} />
              <FaChevronRight onClick={nextImage} className={styles.navIcon} />
            </div>

            {/* Description */}
            <div 
              className={styles.popupDescription} 
              dangerouslySetInnerHTML={{ __html: currentProject.descriptions[currentImageIndex] }} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDisplay;
