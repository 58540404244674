import React from 'react';

const Services = () => {
  return (
    <section className="section service border-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span className="h6 text-color">Our Services</span>
              <h2 className="mt-3 content-title">
                We provide a wide range of creative services
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-desktop"></i>
              <h4 className="mb-3">Web Development</h4>
              <p>
                We offer web development services using ASP.NET or MERN stack, tailored to meet your specific business needs.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-mobile"></i>
              <h4 className="mb-3">Mobile App Development</h4>
              <p>
                Our expertise includes developing mobile apps using Flutter, ensuring seamless performance and user experience.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-desktop"></i>
              <h4 className="mb-3">Windows App Development</h4>
              <p>
                We specialize in creating robust Windows applications with DevExpress, delivering powerful and user-friendly solutions.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-palette"></i>
              <h4 className="mb-3">Interface Design</h4>
              <p>
                Our team excels in crafting intuitive and engaging interface designs that enhance user interaction and satisfaction.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-pie-chart"></i>
              <h4 className="mb-3">Data Engineering</h4>
              <p>
                We provide data engineering services using Python, enabling efficient data management and processing.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-bar-chart"></i>
              <h4 className="mb-3">Data Analysis</h4>
              <p>
                Our data analysis services leverage various tools to extract actionable insights, helping you make informed business decisions.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-exchange-vertical"></i>
              <h4 className="mb-3">Data Scraping</h4>
              <p>
                We offer advanced data scraping services to extract valuable information from various sources, helping you make data-driven decisions.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-pencil-alt"></i>
              <h4 className="mb-3">Content Creation</h4>
              <p>
                Our content creation services produce compelling and relevant content that resonates with your audience and drives engagement.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="service-item mb-5">
              <i className="ti-flag"></i>
              <h4 className="mb-3">Branding</h4>
              <p>
                We help build strong and memorable brands, ensuring your business stands out in the competitive market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
