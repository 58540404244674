import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Testimonial = () => {
  return (
    <section className="section testimonial">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="section-title">
              <span className="h6 text-color">Clients testimonial</span>
              <h2 className="mt-3 content-title">Check what's our clients say about us</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Carousel className="testimonial-carousel">
          <Carousel.Item>
            <div className="row testimonial-wrap">
              <div className="col-lg-6">
                <div className="testimonial-item position-relative">
                  <i className="ti-quote-left text-color"></i>
                  <div className="testimonial-item-content">
                    <p className="testimonial-text">
                      "Fast delivery as promised. Good quality of work, good availability and communication. Well done! Will keep you in my list. Next time again!"
                    </p>
                    <div className="testimonial-author">
                      <h5 className="mb-0 text-capitalize">DEVhq</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="testimonial-item position-relative">
                  <i className="ti-quote-left text-color"></i>
                  <div className="testimonial-item-content">
                    <p className="testimonial-text">
                      "Great working with Muhammad. He delivered as specified, responded well to feedback, and delivered on time. Thanks!"
                    </p>
                    <div className="testimonial-author">
                      <h5 className="mb-0 text-capitalize">Alex van Breukelen</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row testimonial-wrap">
              <div className="col-lg-6">
                <div className="testimonial-item position-relative">
                  <i className="ti-quote-left text-color"></i>
                  <div className="testimonial-item-content">
                    <p className="testimonial-text">
                      "Excellent work - completed perfectly and communicated timeline well. Highly recommended!"
                    </p>
                    <div className="testimonial-author">
                      <h5 className="mb-0 text-capitalize">Dustin Johnson</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="testimonial-item position-relative">
                  <i className="ti-quote-left text-color"></i>
                  <div className="testimonial-item-content">
                    <p className="testimonial-text">
                      "Muhammad did a great job completing my project. He is very knowledgeable and great to work with."
                    </p>
                    <div className="testimonial-author">
                      <h5 className="mb-0 text-capitalize">Clarksburg</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonial;
