import React from 'react';
import image from '../../assets/images/team/team.png';
const TeamMember = ({ name, position, linkedIn }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="team-item-wrap mb-5">
        <div className="team-item position-relative">
          <img src={image} alt={name} className="img-fluid w-60px" />
          <div className="team-img-hover">
            <ul className="team-social list-inline">
              <li className="list-inline-item">
                <a href="#" className="twitter">
                  <i className="fab fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              {linkedIn && (
                <li className="list-inline-item">
                  <a href={linkedIn} target="_blank" rel="noopener noreferrer" className="linkedin">
                    <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="team-item-content">
          <h5 className="ml-5 mt-3 mb-0 text-capitalize">{name}</h5>
          <p className="ml-5">{position}</p>
        </div>
      </div>
    </div>
  );
};

const OurTeam = () => {
  const teamMembers = [
    {
      name: "Muhammad Asif Akbar",
      position: "Founder, CEO",
      linkedIn: "https://www.linkedin.com/in/muhammad-asif-akbar-b9201141/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3Bw0lbQu4YSzKEXkp7S8kZwA%3D%3D",
    },
    {
      name: "Talha Tariq",
      position: "Software Engineer",
      linkedIn: "https://www.linkedin.com/in/talha-tariq-937067219/",
    },
    {
      name: "Mr Talha",
      position: "Software Engineer",
      linkedIn: "https://www.linkedin.com/in/mr-talha-9a113a315",
    },
    {
      name: "Furqan Mujahid",
      image: "images/team/team.png",
    },
    {
      name: "Masood Hussain",
      position: "Software Engineer Intern",
    },
    {
      name: "Ahmad Tariq",
      position: "Flutter Developer",
    },
    // Add more team members here as needed
  ];

  return (
    <section className="section team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <div className="section-title">
              <span className="h6 text-color">Our Team</span>
              <h2 className="mt-3 content-title">Expert Team members to get the best service</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              position={member.position}
              image={member.image}
              linkedIn={member.linkedIn}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
