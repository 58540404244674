import React from 'react'
import TitlePortfolio from '../components/portfolio/TitlePortfolio'
import ProjectDisplay from '../components/portfolio/ProjectDisplay'

const Portfolio = () => {
  return (
    <div>
      <div className="main-wrapper">
        <TitlePortfolio/>
        <section class="section portfolio pb-0">
        <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7 text-center">
                        <div class="section-title">
                            <span class="h6 text-color">Our works</span>
                            <h2 class="mt-3 content-title ">We have done lots of works, lets check some</h2>
                        </div>
                    </div>
                </div>
            </div>
          <ProjectDisplay/>
        </section>
      </div>
      </div>
  )
}

export default Portfolio