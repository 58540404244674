import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Send email using EmailJS
    emailjs.send(
      'service_fm5g3ad', // Replace with your EmailJS service ID
      'template_x77xt0j', // Replace with your EmailJS template ID
      formData,
      'guUBNIxJFNvQKP8M9' // Replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setIsSuccess(true);
      setErrorMessage('');
      setFormData({ name: '', email: '', message: '' }); // Clear form fields
    })
    .catch((error) => {
      console.log('FAILED...', error);
      setErrorMessage('Failed to send the message, please try again.');
    });
  };

  return (
    <div className="main-wrapper">
      <section className="page-title bg-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Contact Us</span>
                <h1 className="text-capitalize mb-4 text-lg">Get in Touch</h1>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link to="/" className="text-white">Home</Link>
                  </li>
                  <li className="list-inline-item">
                    <span className="text-white">/</span>
                  </li>
                  <li className="list-inline-item">
                    <span className="text-white-50">Contact Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-wrap section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <form id="contact-form" className="contact__form" onSubmit={handleSubmit}>
                {/* form message */}
                <div className="row">
                  <div className="col-12">
                    {isSuccess && (
                      <div className="alert alert-success contact__msg" role="alert">
                        Your message was sent successfully.
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger contact__msg" role="alert">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <span className="text-color">Send a message</span>
                <h3 className="text-md mb-4">Contact Form</h3>
                <div className="form-group">
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group-2 mb-4">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="4"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button 
  className="btn btn-main" 
  type="submit"
  style={{
    textDecoration: 'none',
    color: '#f74234', // Start with text color matching the border
    border: "2px solid #f74234",
    borderRadius: "8px",
    padding: "10px 20px",
    backgroundColor: "white", // Start with a white background
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for hover effect
  }}
  onMouseEnter={(e) => {
    e.target.style.backgroundColor = "#f74234"; // Change to the primary color on hover
    e.target.style.color = "white"; // Change text color to white on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.backgroundColor = "white"; // Revert to white background
    e.target.style.color = "#f74234"; // Revert text color to match border
  }}
>
  Send Message
</button>

              </form>
            </div>

            <div className="col-lg-5 col-sm-12">
              <div className="contact-content pl-lg-5 mt-5 mt-lg-0">
                <span className="text-muted">We are Professionals</span>
                <h2 className="mb-5 mt-2">Don’t Hesitate to contact with us for any kind of information</h2>
                <ul className="address-block list-unstyled">
                  <li>
                    <i className="ti-direction mr-3"></i>Lahore, Punjab Pakistan
                  </li>
                  <li>
                    <i className="ti-email mr-3"></i>Email: info@eprimesoftsolutions.com
                  </li>
                  <li>
                    <i className="ti-mobile mr-3"></i>Phone: +92-3044870692
                  </li>
                </ul>
                <ul className="social-icons list-inline mt-5">
                  <li className="list-inline-item">
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#"><i className="fab fa-twitter"></i></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/company/primesoftsolutions/?viewAsMember=true"><i className="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="google-map">
        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d600.63954088552!2d74.26071168769636!3d31.613848038036547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191dd162e4a171%3A0xfffe6dcda318a922!2sPrimesolution!5e0!3m2!1sen!2s!4v1722350541580!5m2!1sen!2s"
            width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
