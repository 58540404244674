import React from "react";
import Header from "./../components/Header";
import Slider from "./../components/home/Slider";
import Intro from "./../components/home/Intro";
import About from "./../components/home/About";
import Services from "../components/home/Services";
import Cta from "../components/home/Cta";
import Testimonial from "../components/home/Testimonial";
import CallToAction from "../components/home/CallToAction";
import Footer from "./../components/Footer";
const Home = () => {
  return (
    <div>
      <div className="main-wrapper">
        <Slider />
        <Intro />
        <About />
        <Services/>
        <Cta/>
        <Testimonial/>
        <CallToAction/>
      </div>
    </div>
  );
};

export default Home;
