import React from 'react'
import TitleAbout from '../components/about/TitleAbout'
import AboutSection from '../components/about/AboutSection'
import AboutInfo from '../components/about/AboutInfo'
import OurTeam from '../components/about/OurTeam'
import TechnologySection from '../components/about/TechnologySection'
const About = () => {
  return (
    <div>
      <div className="main-wrapper">
        <TitleAbout/>
        <AboutSection/>
        <AboutInfo/>
        <OurTeam/>
        <TechnologySection/>
      </div>
      </div>
  )
}

export default About