import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal routing

const Header = () => {
  return (
    <header className="navigation">
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2 col-md-4">
              <div className="header-top-socials text-center text-lg-left text-md-left">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="ti-facebook"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/primesoftsolutions/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="ti-linkedin"></i>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="ti-github"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-10 col-md-8 text-center text-lg-right text-md-right">
              <div className="header-top-info">
                {/* Uncomment the following line to enable the phone link */}
                {/* <a href="tel:+92-3044870692">Call Us : <span>+92-3044870692</span></a> */}
                <a href="mailto:info@eprimesoftsolutions.com">
                  <i className="fa fa-envelope mr-2"></i>
                  <span>info@eprimesoftsolutions.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg py-4" id="navbar">
        <div className="container">
          {/* Uncomment the following line to enable the logo */}
          {/* <img src="images/PrimeSoftSolutionLogo_2.png" alt="Prime Soft Solutions Logo" style={{ width: '100px', height: '80px' }} /> */}

          <Link
        className="navbar-brand"
        to="/"
        style={{ textDecoration: 'none' }}
        onMouseEnter={(e) => (e.currentTarget.firstChild.style.color = '#f74234')}
        onMouseLeave={(e) => (e.currentTarget.firstChild.style.color = 'white')}
      >
        <span style={{ color: 'white', transition: 'color 0.3s ease' }} className="prime-soft">
          Prime Soft
        </span>
        <span style={{ color: '#f74234' }}> Solutions.</span>
      </Link>
      <button
        className="navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExample09"
        aria-controls="navbarsExample09"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="fa fa-bars"></span>
      </button>

          <div className="collapse navbar-collapse text-center" id="navbarsExample09">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About <span className="sr-only"></span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <form
  className="form-lg-inline my-2 my-md-0 ml-lg-4 text-center"
  style={{
    border: '2px solid #f74234', // Add border with the desired color
    borderRadius: '8px',
    padding: '0px', // Optional: Add some padding for better appearance
  }}
>
  <Link to="/contact" className="btn btn-solid-border btn-round-full">
    Get a Quote
  </Link>
</form>

          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
