import React from 'react';

const AboutInfo = () => {
  return (
    <section className="about-info section pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3">
                <span className="text-color mr-2 text-md">01.</span>Our Mission
              </h3>
              <p>
                Our mission is to deliver cutting-edge software solutions that drive innovation and efficiency across diverse industries. We are committed to providing exceptional value and empowering businesses to achieve their full potential.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3">
                <span className="text-color mr-2 text-md">02.</span>Vision
              </h3>
              <p>
                Our vision is to be a global leader in software development, known for our quality, reliability, and commitment to customer success. We aim to continually evolve and expand our expertise to meet the changing needs of the market.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="about-info-item mb-4 mb-lg-0">
              <h3 className="mb-3">
                <span className="text-color mr-2 text-md">03.</span>Our Approach
              </h3>
              <p>
                Our approach is centered around understanding our clients' unique needs and delivering tailored solutions. We combine industry best practices with the latest technology to optimize business processes and enhance operational performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfo;
